import { adminClient } from "@api/admin/AdminClient";
import { DasboardCardComponent } from "@components/admin/dashboards/DashboardCardComponent";
import { DashboardComponent } from "@components/admin/dashboards/DashboardComponent";
import SessionsFrequencyComponent from "@components/admin/dashboards/SessionFrequencyComponent";
import StackBarSectionComponent from "@components/admin/stats/StackBarSectionComponent";
import { GraphPoint } from "@components/admin/stats/StatsHelper";
import { Box } from "@material-ui/core";
import { AdminSessionFrequencyRecencyUsersResponse, AdminSessionFrequencyStatsGetResponse } from "@reshopper/admin-client";
import { Countries } from "@reshopper/web-client";
import { toDanishDateFormat } from "@utils/miscellaneous";
import { useState } from "react";

export default function SessionFrequiencies() {
	const [country, setCountry] = useState<Countries>("dk");
	const [lastRefreshUtc, setLastRefreshUtc] = useState<Date>(new Date());
	const [userSessionFrequencies, setUserSessionFrequencies] = useState<AdminSessionFrequencyStatsGetResponse>();
	const [userSessionFrequencieRangeGraphPoints, setUserSessionFrequencieRangeGraphPoints] = useState<GraphPoint[]>(new Array<GraphPoint>());
	const [frequencyTotal, setFrequencyTotal] = useState(0);

	async function fetchUserSessionFrequencies() {
		if (!country) {
			return;
		}

		const sessionFrequiencies = await adminClient().adminSessionFrequencyStatsGet(country)

		setUserSessionFrequencies(sessionFrequiencies);
		const sessionFrequiencieRanges = await adminClient().adminSessionFrequencyRecencyStatsPost({
			body: {
				country: country
			}
		})
		setUserSessionFrequencieRangeGraphPoints(generateGraphPoints(sessionFrequiencieRanges));
	}

	const convertRecordToMap = (record: Record<string, unknown>): Map<string, string> => {
		return record as unknown as Map<string, string>;
	}

	function generateGraphPoints(sessionAggregations: AdminSessionFrequencyRecencyUsersResponse) {
		let graphPoint = new Array<GraphPoint>();
		const sessionFrequencyCountRanges =
			[
				"today",
				"1",
				"2",
				"3",
				"4",
				"5-9",
				"10-14",
				"15-19",
				"20-24",
				"25-29",
				"30-34",
				"35-39",
				"40-44"
			];

		let total = 0;

		for (let index = 0; index < sessionAggregations.counts.length; index++) {
			const frequencyRange = sessionFrequencyCountRanges[index];
			const frequencyCount = sessionAggregations.counts[index];
			graphPoint.push({
				x: frequencyRange,
				y1: frequencyCount
			});
			
			total += frequencyCount;
		}

		setFrequencyTotal(total);
		
		return graphPoint;
	}

	return <DashboardComponent
		onRefresh={async country => {
			setCountry(country);
			setLastRefreshUtc(new Date());
			fetchUserSessionFrequencies();
		}}>

		{userSessionFrequencies && <Box
			display="flex"
			flexDirection="row"
			flexWrap="Wrap"
		>
			<SessionsFrequencyComponent
				title="DSF"
				currentSessionFrequencies={convertRecordToMap(userSessionFrequencies.todaysSessionFrequencies)}
				previousSessionFrequencies={convertRecordToMap(userSessionFrequencies.yesterdaysSessionFrequencies)}
			/>
			<SessionsFrequencyComponent
				title="WSF"
				currentSessionFrequencies={convertRecordToMap(userSessionFrequencies.currentWeeksSessionFrequencies)}
				previousSessionFrequencies={convertRecordToMap(userSessionFrequencies.lastWeeksSessionFrequencies)}
			/>
			<SessionsFrequencyComponent
				title="MSF"
				currentSessionFrequencies={convertRecordToMap(userSessionFrequencies.currentMonthsSessionFrequencies)}
				previousSessionFrequencies={convertRecordToMap(userSessionFrequencies.lastMonthsSessionFrequencies)}
			/>
		</Box>}

		<Box
			display="flex"
			flexDirection="row"
		>
			<DasboardCardComponent cardTitle={`RECENCY ${frequencyTotal}`}>
				{userSessionFrequencieRangeGraphPoints.length === 0 ?
					<></> :
					<>
						<StackBarSectionComponent
							stats={userSessionFrequencieRangeGraphPoints}
							displayTicks={true}
							labelFontSize={35}
							tickFontSize={22}
							height={344}
							marginLeft={0}
							marginBottom={34}
							barBackgroundColors={["#62AED6"]}
							textColor="#60453F"
							backgroundColor="#fafafa"
						/>
					</>}
			</DasboardCardComponent>
		</Box>
		<div >
			<footer  >

				{toDanishDateFormat(lastRefreshUtc)}
			</footer>
		</div>
	</DashboardComponent>
}
