import StatsGraphTableComponent from "@components/admin/stats/StatsGraphTableComponent";

export default function SessionsFrequencyComponent(
	props: {
		title: string,
		currentSessionFrequencies: Map<string, string>,
		previousSessionFrequencies: Map<string, string>
	}
) {

	function convertSessionFrequencyMapsToSingleMap(): Map<string, string> {
		let singleSessionFrequencyMap = new Map<string, string>();

		const sumOfCurrentSessionFrequencies = sumSessionFrequiencies(props.currentSessionFrequencies);
		const sumOfPreviousSessionFrequencies = sumSessionFrequiencies(props.previousSessionFrequencies);

		Object.keys(props.currentSessionFrequencies).forEach(function (key) {
			const keyAsString = key as string;
			const currentSessionFrequency = +(props.currentSessionFrequencies as any)[keyAsString];
			//const currentSessionFrequencyFormatted = formatNumber((props.currentSessionFrequencies as any)[keyAsString]);
			const previousSessionFrequency = +(props.previousSessionFrequencies as any)[keyAsString];
			//const previousSessionFrequencyFormatted = formatNumber((props.previousSessionFrequencies as any)[keyAsString]);

			const currentSessionFrequencyPercentageOfTotal = (currentSessionFrequency / sumOfCurrentSessionFrequencies * 100).toFixed(1);
			const peviousSessionFrequencyPercentageOfTotal = (previousSessionFrequency / sumOfPreviousSessionFrequencies * 100).toFixed(1);

			singleSessionFrequencyMap.set(
				keyAsString,
				// The correct way to get a value from key from a Map is to use the get() method, but in this case it throws a runtime error.
				// We therefore cast the Map to an "any", and access the value through the [key] accessor
				`${currentSessionFrequencyPercentageOfTotal}% (${peviousSessionFrequencyPercentageOfTotal}%)`);
		});

		// props.currentSessionFrequencies?.forEach((key, value) => ))

		return singleSessionFrequencyMap;
	};

	function sumSessionFrequiencies(sessionFrequencies: Map<string, string>): number {
		return Object.values(sessionFrequencies).reduce((sum: number, current: string) => sum + (+current), 0);
	}

	return <StatsGraphTableComponent
		tableStats={convertSessionFrequencyMapsToSingleMap()}
		tableTitle={props.title}
		variant="large"
	/>
}